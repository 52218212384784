<template>
  <div class="container">
   
    <Header></Header>


    <div class="ny-main">
     
        <div class="blog-g-top">
         <img src="https://api.starmaxintl.com/storage/file/20220921/27ab2460e7d227823d6f71bfe676e1ae3f867bfa.jpg" alt="">
        </div>
        <div class="blog-g-crumbs">
          <i><img src="https://api.starmaxintl.com/storage/file/20220921/f9464ad5ba23c98233e718d148e14a6ca6881aca.png" alt=""></img></i>
		  <el-breadcrumb separator-class="el-icon-arrow-right">
		    <el-breadcrumb-item :to="{ path: 'home' }">Home</el-breadcrumb-item>
		    <el-breadcrumb-item :to="{ path: 'blog' }">Blog</el-breadcrumb-item>
		    <el-breadcrumb-item :to="{ path: 'blog' }">{{category_name}}</el-breadcrumb-item>
		  </el-breadcrumb>
        </div>

        <div class="blog-g-con">
          <div class="blog-g-list">
            <div class="blog-g-item" v-for="(item,index) in model.list" :key="index">
                <div class="blog-g-box-wrap">
                  <div class="blog-g-box">
                    <h3><router-link :to="{name:'blog-detail',query:{'id':item.content_id}}">{{item.name}}</router-link></h3>
                    <p>{{item.description}}</p>
                    <div class="blog-g-lwo cl">
                    
                      <div class="blog-g-more">
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="dot"></i>
                            <i class="dot"></i>
                            <i class="dot"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item3,b) in model.dropdown" :key="b">
                            <i class="blog-g-dropdown-i"><img :src="item3.icon" alt=""></i>{{item3.name}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>

                      </div>

                      <div class="blog-g-date">{{item.create_time}}</div>
                      <div class="label-box">
                        <em v-for="(item2,a) in item.keywords" :key="a">{{item2}}</em>
                      </div>

                    </div>
                  </div>
                  <div class="blog-g-pic"><img :src="item.img_url" alt=""></div>
                </div>
            </div>

          </div>
         <!-- 分页 -->
         <pagination v-show="count > 0" :total="count" :page.sync="query.page" :limit.sync="query.limit" @pagination="bloglist" />
        </div>
        <!-- blog-g-con end -->
    </div>
    <!-- main end  -->
     <Footer></Footer>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import Pagination from '@/components/Pagination'
import { list,categoryinfo } from '@/api/blog'

export default {
  components: { Header, Footer, Pagination, },
  data() {
    return {
      crumbs:"EComentce Development",
	  category_name: '',
	  query: {
        page: 1,
        limit: 3,
      },
	  count: 0,
	  model: {
	    list: [],
		dropdown:[
            {
              icon: "https://api.starmaxintl.com/storage/file/20220825/3cde53ef97614025cfbd53eafdabc3bff98fd3bf.png",
              name:'facebook',
            },
            {
              icon: "https://api.starmaxintl.com/storage/file/20220825/3cde53ef97614025cfbd53eafdabc3bff98fd3bf.png",
              name:'facebook',
            },
            {
              icon: "https://api.starmaxintl.com/storage/file/20220825/3cde53ef97614025cfbd53eafdabc3bff98fd3bf.png",
              name:'facebook',
            },
            {
              icon: "https://api.starmaxintl.com/storage/file/20220825/3cde53ef97614025cfbd53eafdabc3bff98fd3bf.png",
              name:'facebook',
            },
          ]
	  },
      
    };
  },
 
 mounted(){
  $(function(){
    let imgWidth = $('.blog-m1-left').width();
    $('.carousel-img').height(imgWidth/1.833)
  })
 },
 created() {
   this.bloglist()
   this.category_info()
 },

  methods: {
    bloglist(){
	  //alert(this.$route.query.category)
	  list({category:this.$route.query.category,page:this.query.page,limit:this.query.limit}).then(res => {
	    //alert(JSON.stringify(res.data.data.list))
        this.model.list=res.data.data.list
		this.count = res.data.data.count
		//alert(this.query.limit)
      }).catch(() => {
      })
	},
	category_info(){
	  categoryinfo({category:this.$route.query.category}).then(res => {
	    //alert(JSON.stringify(res.data.data))
        this.category_name=res.data.data.category_name
      }).catch(() => {
      })
	}
  },
}
</script>

<style scoped >


.ny-main {
  overflow: hidden;
  padding: 50px 8.3vw;
}


.blog-g-top,
.blog-g-top img{
  width: 100%;
  vertical-align: top;
}

.blog-g-crumbs{
  line-height: 24px;
  overflow: hidden;
  height: 24px;
  margin: 30px 0;
   color: #000;
  font-size: 18px;
}

.blog-g-crumbs i{
  display: inline-block;
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 5px;
 
}
.el-breadcrumb__item{font-size: 22px;}
.blog-g-crumbs i img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.blog-g-con{
  background: #fff;
  padding: 40px;

}

.blog-g-item{
  width: 100%;
  margin-bottom: 60px;
}

.blog-g-item-top{
  width: 100%;
  overflow: hidden;
}

.blog-g-item-top span{
  display: inline-block;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  background: #f3f6f8;
  font-size: 18px;
  color: #0a3c5b;
}

.blog-g-box-wrap{
  display: flex;
}

.blog-g-box{
  flex: 1;
}

.blog-g-pic{
  width: 330px;
  height: 220px;
  margin-left: 40px;
  overflow: hidden;
  border-radius: 8px;
}


.blog-g-pic img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.dot{
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #c9c9c4;
  border-radius: 50%;
  margin: 0 3px;
}

.blog-g-box h3 a{
  line-height: 1;
  font-size: 2.5vw;
  font-weight: normal;
  color: #0a3c5b;
  font-family: DINCound;
}

.blog-g-box p{
  line-height: 32px;
  color: #000;
  font-size: 18px;
  margin-top: 15px;
}

.blog-g-low{
  min-height: 32px;
  margin-top: 20px;
}

.blog-g-date{
  color: #0a3c5b;
  font-size: 18px;
  text-decoration: underline;
  line-height: 32px;
  margin-right: 8%;
}

.blog-g-date,
.label-box{
  display: inline-block;
}

.label-box em{
  margin-right: 20px;
  text-decoration: underline;
  font-size: 18px;
  color: #013656;
}

.blog-g-more{
  float: right;
  margin-top: 8px;
}

.blog-g-dropdown-i{
  width: 22px;
  height: 22px;
  display: inline-block;
  
  margin-right: 10px;
}


.blog-g-dropdown-i img{
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.el-dropdown-menu__item{
  color: #0a3c5b;
  font-size: 18px;
}

.el-dropdown{
  cursor: pointer;
}


</style>
